<template>
  <v-app id="schedule-groups-view">
    <v-main>
      <MetadataLoadingGuard>
        <ProvideLayoutManager :layout-manager="manager">
          <BoundingBox
            v-slot="{ initialized, top: topMargin }"
            @change="onBoundingBoxChange"
          >
            <v-tabs
              v-model="selectedTab"
              slider-color="orange"
              height="50"
              background-color="transparent"
              class="mb-6"
              show-arrows
            >
              <template v-for="{ text, enabled } in tabs">
                <v-tab v-if="enabled" :key="text">
                  {{ text }}
                </v-tab>
              </template>
            </v-tabs>
            <v-tabs-items v-model="selectedTab">
              <template v-for="{ component, enabled } in tabs">
                <v-tab-item v-if="enabled" :key="component.props.tqlProperty">
                  <app-window
                    v-if="initialized"
                    closable
                    :landing-page="component"
                    :on-close="() => $emit('close')"
                  />
                </v-tab-item>
              </template>
            </v-tabs-items>
            <AppLayoutSnackBarLauncher />
            <AppLayoutSideSheet :top="topMargin" />
            <AppLayoutAlertLauncher />
          </BoundingBox>
        </ProvideLayoutManager>
      </MetadataLoadingGuard>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'

import AppLayoutAlertLauncher from '@/tt-app-layout/components/AppLayoutAlertLauncher.vue'
import AppLayoutSideSheet from '@/tt-app-layout/components/AppLayoutSideSheet.vue'
import AppLayoutSnackBarLauncher from '@/tt-app-layout/components/AppLayoutSnackBarLauncher.vue'
import { PageInterface } from '@/tt-app-layout'
import ProvideLayoutManager from '@/tt-app-layout/components/ProvideLayoutManager.vue'
import LayoutManager from '@/tt-app-layout/LayoutManager'

import BoundingBox, { BoundingBoxData } from '../../components/BoundingBox.vue'
import MetadataLoadingGuard from '../../components/MetadataLoadingGuard.vue'

export default Vue.extend({
  name: 'PeriodicListView',
  components: {
    AppLayoutAlertLauncher,
    AppLayoutSideSheet,
    AppLayoutSnackBarLauncher,
    BoundingBox,
    MetadataLoadingGuard,
    ProvideLayoutManager,
  },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    siteTaskPeriodicEnabled: {
      type: Boolean,
      default: false,
    },
    mobileSchedulePeriodicEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      manager: new LayoutManager(this.$eventManager),
      boundingBoxData: undefined as BoundingBoxData,
      selectedTab: null,
    }
  },
  computed: {
    siteTasksPage(): PageInterface {
      return {
        is: 'PeriodicList',
        props: {
          clientId: this.clientId,
          tqlProperty: 'scheduleModelSiteTaskSchedules',
        },
      }
    },
    mobileSchedulesPage(): PageInterface {
      return {
        is: 'PeriodicList',
        props: {
          clientId: this.clientId,
          tqlProperty: 'scheduleModelMobileScheduleGroups',
        },
      }
    },
    tabs(): { text: string; component: PageInterface; enabled: boolean }[] {
      const tabs = [
        {
          text: this.translate('mobile-schedules'),
          component: this.mobileSchedulesPage,
          enabled: this.mobileSchedulePeriodicEnabled,
        },
        {
          text: this.translate('site-task-schedules'),
          component: this.siteTasksPage,
          enabled: this.siteTaskPeriodicEnabled,
        },
      ]

      return tabs
    },
  },
  beforeDestroy() {
    if (this.manager) {
      this.manager.destroy()
    }
  },
  methods: {
    onBoundingBoxChange(data: BoundingBoxData): void {
      this.boundingBoxData = data
    },

    translate(key: string): string {
      return this.$t(`res.${key}.labelPlural`)
    },
  },
})
</script>
